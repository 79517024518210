
import './App.css';
import NavBar from './NavBar';
import CA from './CA';
import TopCarousel from './carousel/TopCarousel';
import About from './About';
import HowToBuy from './HowToBuy';
import BurnCount from './BurnCount';
import MemeGallery from './MemeGallery';
import Footer from './Footer';
import Buttons from './Buttons';

function App() {
  return (
    <div className="App font-chilanka">
      <NavBar />
      <img className="w-full" src="new-banner.jpg" alt='banner' />
      <div className="flex flex-col mt-10">
        {/* <TopCarousel /> */}
        <Buttons />

        <CA />

        <img className="h-full" src="BitcoinFraud.png" alt="" />

        <About />
        <HowToBuy />
        <div className="flex flex-col">
          <h1 className="text-6xl mt-6">Roadmap:</h1>
          <h1 className="text-4xl mt-6">
            1.) JP Morgan reserves emptied and airdropped to holders
          </h1>
          <h1 className="text-4xl mt-6">
            2.) Holder party at the Dimun Penthouse
          </h1>
          <h1 className="text-4xl mt-6">
            3.) President Jamy makes $dimun global reserve currency
          </h1>
          <h1 className="text-4xl mt-6">4.) Dimun hands</h1>

          <div class="flex flex-col md:flex-row items-center justify-center gap-10 mb-8 mt-8">
            <img
              class="w-96 md:w-128 h-96 md:h-128 rounded-lg object-contain"
              src="dimun-picture.jpg"
              alt="Dimun Picture"
            />

            <img
              class="w-80 md:w-96 h-80 md:h-96 rounded-lg object-cover"
              src="Pointed Finger.png"
              alt="Pointed Finger"
            />
          </div>
        </div>
        {/* <MemeGallery /> */}
        <img src="dimun-background.jpg" alt="" />
        <Footer />
      </div>
    </div>
  );
}

export default App;
