import React from "react";

const About = () => {
  return (
    <div className="px-6 md:px-40 flex flex-col md:flex-row p-10 gap-12 w-full justify-between mb-8 items-center">
      <div className="md:flex-1 text-center md:text-left">
        <h1 className="text-4xl md:text-5xl mb-4 font-semibold">
          Jamy Dimun 2024
        </h1>
        <p className="text-xl md:text-2xl">
          America was founded on two kinds of freedoms: free markets, and the
          freedom to make a lot of fucking money. Jamy Dimun has conquered both
          of these pursuits, and with nothing else to chase,{" "}
          <a
            target="_blank"
            className="underline"
            href="https://reuters.com/world/us/bill-ackman-calls-jpmorgan-ceo-dimon-run-us-president-2023-05-31/"
          >
            he is setting his eyes on the obvious next step:
          </a>{" "}
          the highest office in the land. The United States needs a corporate chad to clean up the mess made by incompetent politicians and return the US to greatness—Jamy Dimun is that chad.
        </p>
      </div>
      <img
        className="w-64 md:w-100 h-64 md:h-80 rounded-lg object-cover"
        src="Building.png"
        alt="jamy-building"
      />
    </div>
  );
};

export default About;
